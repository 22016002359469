import React from "react";
import { Image, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import Link from "../../../core/Components/Link";

const MultiCallout = ({ fields }) => {
  const variantClass = fields.styles.fields.Title.value;
  const cardCount = fields.calloutBlocks.length;

  return (
    <div
      className={`campaign-multi-callout-container ${variantClass}`}
      id={fields.Id.value}
    >
      <div className="campaign-multi-callout-container-banner">
        <div className="campaign-multi-callout-container-banner-heading">
          <Text field={fields.title} />
        </div>
        <div
          className={`callout-block ${
            cardCount % 2 === 0 ? "two-block" : "three-block"
          }`}
        >
          {fields.calloutBlocks.map((callout) => (
            <div key={callout.id} className="callout-card">
              {callout.fields.Image?.value ? (
                <div className="callout-background-image">
                  <Image field={callout.fields.Image} />
                </div>
              ) : null}

              {callout.fields.title?.value ? (
                <div className="callout-heading">
                  <Text field={callout.fields.title} />
                </div>
              ) : null}

              <div className="callout-details">
                <RichText field={callout.fields.description} />
              </div>
            </div>
          ))}
        </div>
        <div className="multi-callout-description">
          <RichText field={fields.description} />
        </div>
        {fields.getStarted.value.href ? (
          <div className="multi-callout-get-started">
            <Link field={fields.getStarted} className="cta-button tertiary" />
          </div>
        ) : null}
      </div>
      {fields.breakLine.value ? <div className="break-line" /> : null}
    </div>
  );
};

export default MultiCallout;
